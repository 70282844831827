<template>
  <CModal id="TicketSchedule" class="ActionModel" size="lg" :show.sync="Toggle" title="設定班次時刻" :centered="true" @update:show="HandleHideModal()">
    <h6 class="d-flex justify-content-between mb-2">
      <span class="font-weight-bold">班次與時刻</span>
      <CButton color="success" size="sm" variant="ghost" v-c-tooltip="$t('Global.Add')" @click="AddSchedule">
        <CIcon name="cil-plus" class="c-icon-custom-size"/> {{ $t('Global.Add') }}
      </CButton>
    </h6>
    <template v-if="ScheduleList.length > 0">
      <CRow>
        <CCol col="3">號碼</CCol>
        <CCol col="4">時間</CCol>
        <CCol col="4">運輸時間</CCol>
      </CRow>
      <hr class="my-2">
      <CRow v-for="(ScheduleData, Index) in ScheduleList" :key="Index">
        <CCol col="3">
          <CInput type="text" v-model="ScheduleData.Number" />
        </CCol>
        <CCol col="4">
          <v-date-picker v-model="ScheduleData.Time" mode="time">
            <template v-slot="{ inputValue, inputEvents }">
              <CInput :value="inputValue" v-on="inputEvents">
                <template #append-content>
                  <CIcon name="cil-clock"/>
                </template>
              </CInput>
            </template>
          </v-date-picker>
        </CCol>
        <CCol col="4">
          <CInput type="number" v-model="ScheduleData.Duration">
            <template #append-content>
              分
            </template>
          </CInput>
        </CCol>
        <CCol col="1" class="text-right">
          <CButton color="danger" size="sm" variant="ghost" v-c-tooltip="$t('Global.Remove')" @click="ScheduleList.splice(Index, 1)">
            <CIcon name="cil-trash" class="c-icon-custom-size"/>
          </CButton>
        </CCol>
      </CRow>
    </template>
    <CButton v-if="ScheduleList.length === 0" color="info" block @click="AddSchedule" class="py-2">
      {{ $t('Global.Add') }}第一個班次
    </CButton>
    <template #footer-wrapper>
      <CElementCover
        v-if="(Submit === true)"
        :boundaries="[{ sides: ['center', 'center'], query: '#TicketSchedule' }]"
        :opacity="0.8"
      >
        <CSpinner size="sm" color="primary"/>
      </CElementCover>
      <footer class="modal-footer border-top-0">
        <CButton id="Finish" color="success" @click="Finish">
          {{ $t('Global.Finish') }}
        </CButton>
      </footer>
    </template>
  </CModal>
</template>

<script>
export default {
  props: ['Toggle', 'ProductData'],
  name: 'TicketSchedule',
  components: {
  },
  data() {
    return {
      Submit: false,
      ScheduleList: []
    }
  },
  computed: {
  },
  mounted() {
    this.Init().catch((err) => {
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      this.ScheduleList = this.ProductData.Variable.ScheduleList || []
      const Execute = []
      return Promise.all(Execute).then(() => {
        return true
      }).catch((err) => {
        throw err
      })
    },
    AddSchedule () {
      this.ScheduleList.push({
        Number: '',
        Time: '',
        Duration: ''
      })
    },
    Finish () {
      this.$emit('Finish', this.ScheduleList)
      this.HandleHideModal()
    },
    HandleHideModal () {
      this.$emit('update:Toggle', false)
    }
  }
}
</script>

